/*
 * Created on Jul 20, 2017 9:57:39 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Config} from './base/config';
import {AmThemeService} from './theme/amtheme/amtheme.service';

@Component({
        selector: 'app-root',
        template: `<router-outlet></router-outlet>`
})
export class AppComponent {

    constructor(
        serviceTitle: Title,
        themeService: AmThemeService
    ) {
        serviceTitle.setTitle(Config.APPLICATION_NAME);
        themeService.init(Config.DEFAULT_THEME);
    }
}