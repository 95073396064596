/* 
 * Created on Jul 20, 2017 9:57:39 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {AmBreadcrumbComponent} from './ambreadcrumb.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		MatCardModule,
		MatIconModule
	],
	declarations: [		
		AmBreadcrumbComponent
	],
	exports: [
		AmBreadcrumbComponent
	],
	providers: [
	]
})
export class AmBreadcrumbModule {	
}