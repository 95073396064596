/*
 * Created on Aug 20, 2021
 * Copyright Amix System S.r.l.
 * Alla rights reserved.
 */
import {ChangeDetectorRef, ChangeDetectionStrategy, Component} from '@angular/core';
import {AmThemeConstants, AmThemeMap} from './amtheme.constants';
import {AmThemeService} from './amtheme.service';

@Component({
    selector: 'am-theme',
    templateUrl: './amtheme.html',
    styleUrls: ['./amtheme.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmThemeComponent {
    readonly options: AmThemeMap[] = AmThemeConstants.THEME_LIST;

    constructor(
        private service: AmThemeService,
        private changeDetector: ChangeDetectorRef
    ) {
    }

    setTheme(key: string): void {
        this.service.set(key);
        this.changeDetector.markForCheck();
    }
}