/* 
 * Created on Mar 5, 2018 7:39:13 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AmZoomDirective} from './amzoom.directive';
import {AmZoomService} from './amzoom.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		AmZoomDirective
	],
	exports: [
		AmZoomDirective
	],
	providers: [
		AmZoomService
	]
})
export class AmZoomModule {	
}


