/* 
 * Created on Jun 4, 2017 9:35:27 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

export const routes: Routes = [
	{path: '', redirectTo: 'pages', pathMatch: 'full'},
	{path: '**', redirectTo: 'pages'}
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {useHash: true});


