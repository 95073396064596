/* 
 * Created on Dec 22, 2018 9:20:35 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {registerLocaleData} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class I18nService {
    private static defaultLocale: string;
        
    static getDefaultLocale(): string {
        
        if(!I18nService.defaultLocale) {
            const wn: Navigator = window.navigator;
            const locale: string = wn.languages ? wn.languages[0] : "it-IT";
            const localeLang: string = wn.languages ? wn.languages[1] : "it";

            import(
                /* webpackExclude: /\.d\.ts$/ */
                /* webpackMode: "eager" */
                /* webpackChunkName: "i18n-extra" */
                '@angular/common/locales/' + locale
            ).then(
                lang => import(
                    /* webpackExclude: /\.d\.ts$/ */
                    /* webpackMode: "eager" */
                    /* webpackChunkName: "i18n-extra" */
                    '@angular/common/locales/extra/' + locale
                ).then(
                    extraLang => registerLocaleData(lang.default, locale, extraLang.default)
                )
            ).catch(
                () => import(
                    /* webpackExclude: /\.d\.ts$/ */
                    /* webpackMode: "eager" */
                    /* webpackChunkName: "i18n-extra" */
                    '@angular/common/locales/' + localeLang
                ).then(
                    lang => import(
                        /* webpackExclude: /\.d\.ts$/ */
                        /* webpackMode: "eager" */
                        /* webpackChunkName: "i18n-extra" */
                        '@angular/common/locales/extra/' + localeLang
                    ).then(
                        extraLang => registerLocaleData(lang.default, locale, extraLang.default)
                    )
                )
            );
            
            if(!localStorage.getItem('locale.lang')) {
                if(localeLang == "it") {
                    localStorage.setItem('locale.lang', localeLang);
                } else {
                    localStorage.setItem('locale.lang', "en");
                }
            }
            
            I18nService.defaultLocale = locale;
        }
        
        return I18nService.defaultLocale;
    }
    
    setLang(lang: string): void {
        localStorage.setItem('locale.lang', lang);
    }
    
    getLang(): string {
        return localStorage.getItem('locale.lang')
    }
}