/* 
 * Created on Jul 20, 2017 9:57:39 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {NoopAnimationsModule} from '@angular/platform-browser/animations';

import {I18nService} from './theme/base/i18n.service';
import {PagesModule} from './pages/pages.module';
import {AppComponent} from './app.component';
import {routing} from './app.routing';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    PagesModule,
    routing
  ],
  providers: [
    I18nService,
    {provide: LOCALE_ID, useValue: I18nService.getDefaultLocale()},
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
