/* 
 * Created on Apr 12, 2018 9:33:05 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

import {SelectionMode} from '../base/selection.mode';
import {AmZoomEngine} from './amzoom.engine';
import {AmZoomModel} from './amzoom.model';
import {AmZoomService} from './amzoom.service';

@Directive({
    selector: '[am-zoom]'
})
export class AmZoomDirective implements OnInit {
	@Input() zoomname: string;
	@Input() engine: AmZoomEngine;	
	@Input() dsturl: string;
	@Input() selmode: number = SelectionMode.SINGLE_SELECTION;
	
	@Output()
	zoomBack: EventEmitter<any> = new EventEmitter<any>();

	constructor(private service: AmZoomService) {		
	}
	
	ngOnInit(): void {		
        
		const zoom: AmZoomModel = this.service.getZoomSelected(
			this.zoomname, this.engine.getZoomNameCompare
		);		
		
		if(zoom) {

			if(
				(this.zoomBack.observers.length > 0) && 
				((zoom.dirty) || (zoom.selected) || (zoom.extra))
			) {
				const event: any = {};				
				event.name = zoom.name;
				
				if(zoom.dirty) {
					event.form = zoom.form;
				}
				
				if(zoom.extra) {
					event.extra = zoom.extra;
				}
				
				if(zoom.selected) {
					event.selected = zoom.selected;
				}				
				
				this.zoomBack.emit(event);
			}
		}
	}
		
	@HostListener('click', ['$event']) 	
	onClick(event: any): void {
		event.preventDefault();
		this.service.addLstZoom(this.zoomname, this.engine, this.dsturl, this.selmode);
	}
}


