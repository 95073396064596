/* 
 * Created on Jul 20, 2017 11:32:30 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
export class AuthModel {
    id: number;
    daadesut: string;
    daaltaut: number;
    daascaut: number;
    gruppo: AuthGruppo;
    daatiput: string;
}

export class AuthGruppo {
    id: number;
    daadesgr: string;
}

export class Ruoli {
    static readonly RUOLO_FORNITO: string = "F";
    static readonly RUOLO_VENDITO: string = "V";
}