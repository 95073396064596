/* 
 * Created on Aug 19, 2017 11:51:35 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatNativeDateModule} from '@angular/material/core';

import {AuthService} from '../auth/auth.service';
import {AuthGuard} from '../guards/auth.guard';
import {AmBreadcrumbModule} from '../theme/ambreadcrumb/ambreadcrumb.module';
import {AmMenuItemModule} from '../theme/ammenuitem/ammenuitem.module';
import {AmMessagesModule} from '../theme/ammessages/ammessages.module';
import {AmZoomModule} from '../theme/amzoom/amzoom.module';
import {BandieraModule} from '../pages/geo/nazione/bandiera.module';
import {I18nService} from '../theme/base/i18n.service';
import {MediaService} from '../theme/base/media.service';
import {AmThemeModule} from '../theme/amtheme/amtheme.module';

import {PagesInterceptor} from './pages.interceptor';
import {PagesComponent} from './pages.component';
import {routing} from './pages.routing';
import {PagesService} from './pages.service';

import {UtenteService} from './profilo/utente/utente.service';
import {WorkspaceService} from './workspace.service';

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
        MatProgressBarModule,
		MatSidenavModule,
		MatToolbarModule,
		AmBreadcrumbModule,
		AmMenuItemModule,
        AmMessagesModule,
        AmThemeModule,
		AmZoomModule,
        BandieraModule,
		routing 
	],
	declarations: [
		PagesComponent
	],
	providers: [
        {provide: HTTP_INTERCEPTORS, useClass: PagesInterceptor, multi: true},
		AuthGuard,
		AuthService,
		PagesService,
		UtenteService,
		WorkspaceService,
        I18nService,
		MediaService
	]
})
export class PagesModule {
}
