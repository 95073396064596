/* 
 * Created on Aug 19, 2017 3:25:07 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[am-submenu]'
})
export class AmSubMenuDirective implements AfterViewInit {
	
	constructor(private el: ElementRef) {       
    }
	
	ngAfterViewInit(): void {
		const element: any = this.el.nativeElement;
		let parent: HTMLElement = element.parentElement;
		let multiplier: number = 0;
		let augmenter: number = 0;
        
		while((parent != null) && (parent.tagName.toLowerCase() != 'mat-sidenav')) {
			multiplier = multiplier + 5 + augmenter;
            augmenter++;
			parent = parent.parentElement;
		}
		
        const treeIcon: HTMLElement = parent.querySelector('a.mat-list-icon');
		const left: number = (treeIcon.clientWidth) * (multiplier / 4);		
        const children: HTMLElement[] = element.querySelectorAll('am-menuitem .tree-icon');
		
		for(var child of children) {
			
			if(child.parentElement.parentElement.parentElement.parentElement == element) {
				child.parentElement.style.marginLeft = +left + 'px';
			}
		}
	}
}

