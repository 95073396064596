/* 
 * Created on Apr 13, 2018 9:39:37 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';

import {SelectionMode} from '../base/selection.mode';
import {AmZoomEngine} from './amzoom.engine';
import {AmZoomModel} from './amzoom.model';

const NUM_REG: RegExp = /^[0-9]+$/;

@Injectable()
export class AmZoomService {
	
	constructor(
		protected router: Router, 		
		protected location: Location
	) {
	}
	
	getZoomValid(zoom: any): boolean {		
		
		return (
			(NUM_REG.test(zoom)) && (		
				(+zoom == SelectionMode.SINGLE_SELECTION) ||
				(+zoom == SelectionMode.MULTI_SELECTION) 
			)
		);
	}
	
	getZoomLst(href: string): AmZoomModel[] {
		const tmp: string = localStorage.getItem('zoom.count');
		
		if((tmp) && (NUM_REG.test(tmp))) {
			const lst: AmZoomModel[] = [];
			const count: number = +tmp;
			let found: boolean = false;
            let model: AmZoomModel;
            
			for(var idx = count - 1; idx >= 0; idx--) {
				model = this.getZoomDet(idx);
				
				if((model.routedst != href) && (!found)) {
					
					if(
						(model.routesrc != href) || 
						((!model.selected) && (!model.form))
					) {
						this.removeZoom(idx);
					}
					
					continue;
				} else {
					found = true;
				}
				
				lst.push(model);
			}
			
			if(lst.length > 0) {			
				return lst;
			}
		}
	}
	
	getZoomLstSrc(href: string): AmZoomModel[] {
		const tmp: string = localStorage.getItem('zoom.count');
		
		if((tmp) && (NUM_REG.test(tmp))) {
			const lst: AmZoomModel[] = [];
			const count: number = +tmp;
			let model: AmZoomModel;
            
			for(var idx = count - 1; idx >= 0; idx--) {
				model = this.getZoomDet(idx);
								
				if(model.routesrc == href) {
					break;
				} else {
					lst.unshift(model);
				}
			}
			
			return lst;
		}
	}
	
	getZoomCurrent(): AmZoomModel {
		const tmp: string = localStorage.getItem('zoom.count');
		
		if((tmp) && (NUM_REG.test(tmp))) {
			return this.getZoomDet(+tmp - 1);
		}		
	}
	
	getZoomFirst(): AmZoomModel {
		const tmp: string = localStorage.getItem('zoom.count');
		
		if((tmp) && (NUM_REG.test(tmp))) {
			return this.getZoomDet(0);
		}
	}	
	
	getZoomSelected(name: string, nameCompare: (name: string, namels: string) => boolean): AmZoomModel {
		const tmp: string = localStorage.getItem('zoom.count');
		
		if((tmp) && (NUM_REG.test(tmp))) {
            const zoom: AmZoomModel = this.getZoomDet((+tmp - 1), true);
			
			if(zoom.routesrc == this.location.path(true)) {

				if(nameCompare(name, zoom.name)) {
					this.removeZoom(zoom.idx);
					return zoom;
				}
			}
		}
	}
	
	backZoom(): void {
		const zoom: AmZoomModel = this.getZoomCurrent();
		this.router.navigateByUrl(zoom.routesrc);
	}
	
	addLstZoom(name: string, engine: AmZoomEngine, dsturl: string, selmode: number): void {
		const tmp: string = localStorage.getItem('zoom.count');
		let count: number;
        
		if(tmp != null) {
			count = +tmp;			
		} else {
			count = 0;			
		}
		
		const formData: any = engine.getFormData();
		const extraData: any = engine.getExtraData();
		const url: UrlTree = this.router.createUrlTree([dsturl], {queryParams: {zoom: selmode}});
		
		localStorage.setItem('zoom.name.' + count, name);
		localStorage.setItem('zoom.form.' + count, JSON.stringify(formData.value));
		
        if(extraData) {
            localStorage.setItem('zoom.extra.' + count, JSON.stringify(extraData));
        }
		
		localStorage.setItem('zoom.form.dirty.' + count, '' + formData.dirty);
		localStorage.setItem('zoom.routesrc.' + count, this.location.path(true));
		localStorage.setItem('zoom.routedst.' + count, url.toString());
		localStorage.setItem('zoom.selectionmode.' + count, '' + selmode);
		localStorage.setItem('zoom.dsttype.' + count, 'lst');
		localStorage.setItem('zoom.count', '' + (++count));		
		
		this.router.navigateByUrl(url);		
	}
	
	addDetZoom(dsturl: string): void {
		const tmp: string = localStorage.getItem('zoom.count');
		let count: number;
		
		if(tmp != null) {
			count = +tmp;			
			
			if((count) && (count >= 1)) {
                const zoom: AmZoomModel = this.getZoomDet(count - 1);
				const url: UrlTree = this.router.createUrlTree([dsturl], {queryParams: {zoom: zoom.selectionmode}});
				
				localStorage.setItem('zoom.name.' + count, zoom.name);
				localStorage.setItem('zoom.routesrc.' + count, this.location.path(true));
				localStorage.setItem('zoom.routedst.' + count, url.toString());
				localStorage.setItem('zoom.selectionmode.' + count, '' + zoom.selectionmode);
				localStorage.setItem('zoom.dsttype.' + count, 'det');
				localStorage.setItem('zoom.count', '' + (++count));				
				
				this.router.navigateByUrl(url);
				return;
			}			
		}
		
		this.router.navigateByUrl(dsturl);
	}
	
	setZoom(data: any): void {
		let zoom: AmZoomModel = this.getZoomCurrent();
        
		if(zoom.dsttype == 'det') {
			zoom = this.getZoomDet(zoom.idx - 1);
		}
		
		localStorage.setItem('zoom.selected.' + zoom.idx, JSON.stringify(data));
		this.router.navigateByUrl(zoom.routesrc);
	}
	
	updateZoomDst(idx: number, dsturl: string): void {
		localStorage.setItem('zoom.routedst.' + idx, dsturl);
	}
	
	clearZoom(excludefirst: boolean = false): void {		
		const tmp: string = localStorage.getItem('zoom.count');
		let count: number;
		let idx: number;
		let offset: number;
		
		if(tmp != null) {
			count = +tmp;	
			offset = (excludefirst) ? 1 : 0;
		} else {
			return;
		}

		for(idx = count - 1; idx >= offset; idx--) {
			
			if(localStorage.getItem('zoom.selected.' + idx) == null) {				
				this.removeZoom(idx);
			} else {
				break;
			}
		}
	}	
 	
	private getZoomDet(idx: number, full: boolean = false): AmZoomModel {
		const name: string = localStorage.getItem('zoom.name.' + idx);		
		const routesrc: string = localStorage.getItem('zoom.routesrc.' + idx);
		const routedst: string = localStorage.getItem('zoom.routedst.' + idx);
		const selectionmode: number = +localStorage.getItem('zoom.selectionmode.' + idx);
		const dsttype: string = localStorage.getItem('zoom.dsttype.' + idx);		
		const tmpExtra: string = localStorage.getItem('zoom.extra.' + idx);	

        let tmp: string;
		let dirty: boolean;
                
        tmp = localStorage.getItem('zoom.form.dirty.' + idx);							
		
		if(tmp) {
			dirty = (tmp == 'true');
		}

		let form: any;
		let extra: any;
		let	selected: any;		
        		
		if(tmpExtra) {
			extra = JSON.parse(tmpExtra);
		}
		
		if(full) {
			
			if((tmp = localStorage.getItem('zoom.form.' + idx))) {
				form = JSON.parse(tmp);
			}
			
			if((tmp = localStorage.getItem('zoom.selected.' + idx))) {
				selected = JSON.parse(tmp);
			}
			
		} else {
			selected = (localStorage.getItem('zoom.selected.' + idx) != null);
			form = (localStorage.getItem('zoom.form.' + idx) != null);
		}
		
		return {idx, name, routesrc, routedst, selectionmode, dsttype, form, dirty, selected, extra};
	}
	
	private removeZoom(idx: number): void {
		localStorage.removeItem('zoom.name.' + idx);
		localStorage.removeItem('zoom.extra.' + idx);
		localStorage.removeItem('zoom.form.' + idx);
		localStorage.removeItem('zoom.form.dirty.' + idx);
		localStorage.removeItem('zoom.routesrc.' + idx);
		localStorage.removeItem('zoom.routedst.' + idx);
		localStorage.removeItem('zoom.selectionmode.' + idx);
		localStorage.removeItem('zoom.dsttype.' + idx);
		localStorage.removeItem('zoom.selected.' + idx);
				
		if(idx == 0) {
			localStorage.removeItem('zoom.count');		
		} else {
			localStorage.setItem('zoom.count', '' + idx);
		}
	}
}