/* 
 * Created on Aug 22, 2017 11:57:17 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';

import {AmBreadcrumbModel} from './ambreadcrumb.model';

@Component({
	selector: 'am-breadcrumb',
	templateUrl: 'ambreadcrumb.html',
	styleUrls: ['ambreadcrumb.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AmBreadcrumbComponent {
	@Input() 
    model: AmBreadcrumbModel[];
}

