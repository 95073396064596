/*
 * Created on Aug 20, 2021
 * Copyright Amix System S.r.l.
 * Alla rights reserved.
 */
import {Inject, Injectable} from '@angular/core';

import {AmThemeConstants, AmThemeMap} from './amtheme.constants';

@Injectable()
export class AmThemeService {
    private loadFn: () => void;

    init(key: string): void {
        const customTheme: string = localStorage.getItem('amtheme.custom');

        if(
            (!customTheme) ||
            (!this.set(customTheme))
        ) {
            this.set(key);
        }
    }

    get(): string {
        const el: HTMLElement = this.getActiveStylesheet();

        if(el) {
            return el.getAttribute("am");
        }
    }

    set(key: string): boolean {

        const theme: AmThemeMap = AmThemeConstants.THEME_LIST.find((t: AmThemeMap) => {
            return (t.key === key);
        });

        if(theme) {
            const current: HTMLElement = this.getActiveStylesheet();
            const active: HTMLElement = this.setStylesheet(key);
            active.setAttribute('href', theme.href);

            localStorage.setItem('amtheme.custom', key);

            if(current) {

                this.loadFn = () => {
                    current.remove();
                    window.removeEventListener('load', this.loadFn);
                };

                window.addEventListener('load', this.loadFn);
            }

            return true;
        }
        return false;
    }
    
    private getActiveStylesheet(): HTMLElement {

        return document.head.querySelector(
            `link[rel="stylesheet"].${AmThemeConstants.THEME_KEY}`
        );
    }

    private setStylesheet(key: string): HTMLElement {
        const el: HTMLElement = document.createElement("link");
        el.setAttribute("rel", "stylesheet");
        el.setAttribute("am", key);
        el.classList.add(AmThemeConstants.THEME_KEY);
        document.head.appendChild(el);
        return el;
    }
}