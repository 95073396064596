/* 
 *  Created on Jul 15, 2019
 *  Copyright Alfredo Marchini
 *  http://www.alfredomarchini.it
 */
import {HttpClient} from '@angular/common/http';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, 
    Input, OnDestroy, ViewEncapsulation
} from '@angular/core';

import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

const BASE_URL: string = 'assets/bandiere/';
const DEFAULT_DIR: string = '4x3';
const SQUARE_DIR: string = '1x1';

@Component({
	selector: 'bandiera',
	templateUrl: 'bandiera.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BandieraComponent implements OnDestroy {	
    
    _url: string;
    _sanitized: SafeUrl;
    _dex: string;
    
    private _code: string;
    private _squared: boolean = false;
    
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef
    ) {        
    }
    
    ngOnDestroy(): void {
        
        if(this._url) {            
            URL.revokeObjectURL(this._url);            
            delete this._url;
        }        
    }
    
    get code(): string {
        return this._code;
    }
    
    @Input()
    set code(code: string) {
        
        if(this._code != code) {
            this.setUrl(code, this._squared);
        }
        
        this._code = code;
    }
    
    get squared(): boolean {
        return this._squared;
    }
    
    @Input()
    set squared(squared: boolean) {
        
        if(this.squared != squared) {
            this.setUrl(this._code, squared);
        }
        
        this._squared = squared;
    }
    
    get dex(): string {
        return this._dex;
    }
    
    @Input()
    set dex(dex: string) {
        
        if(this._dex != dex) {
            this._dex = dex;
            this.changeDetector.markForCheck();
        }
    }
    
    private setUrl(code: string, squared: boolean): void {
        let url: string;
        
        if((code) && (code.length > 1) && (code.length <= 5)) {
            url = BASE_URL;
            
            if(squared) {
                url += SQUARE_DIR;
            } else {
                url += DEFAULT_DIR;
            }
            
            url += '/' + code.toLowerCase() + ".svg";
            
            this.http.get(url, {responseType: 'blob'}).subscribe(
                (res: Blob) => {
                    this.setImgUrl(res);
                    this.changeDetector.markForCheck();
                },
                () => {
                    this.setImgUrl();
                }
            );
            
        } else {        
            this.setImgUrl();
        }
    }
    
    private setImgUrl(data?: Blob) {
        let check: boolean = false;
        
        if(this._url) {
            delete this._sanitized;
            URL.revokeObjectURL(this._url);    
            delete this._url;        
            check = true;
        }
        
        if((data) && (data.size > 0)) {
            this._url = URL.createObjectURL(data);
            this._sanitized = this.sanitizer.bypassSecurityTrustUrl(this._url);
            check = true;
        }
        
        if(check) {
            this.changeDetector.markForCheck();
        }
    }
}

