/* 
 * Created on Mar 6, 2017 11:07:23 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';

import {Config} from '../base/config';
import {UtenteService} from '../pages/profilo/utente/utente.service';
import {AuthModel} from './auth.model';

@Injectable()
export class AuthService {
    private static sessionExpired: boolean;
    
	redirectUrl: string;
	
	private static subject: Subject<any> = new Subject<any>();
	
	constructor(private service: UtenteService) {
	}
	
	onLoginChanged(): Observable<any> {
		return AuthService.subject;
	}
	
	login(userLogin: string, userPasswd: string): Observable<any> {
        const subject: ReplaySubject<any> = new ReplaySubject<any>(1);
		
		this.service.login(userLogin, userPasswd).subscribe(
			(res: any) => {

				if(res.result) {
					localStorage.setItem('user.token', res.token);
					localStorage.setItem('user.model', JSON.stringify(res.model));
                    this.resetLastRequest();
                    delete AuthService.sessionExpired;
                    
                    if(
                        (res.infoMessages?.length == 1) &&
                        (res.infoMessages[0] == 'passwd.expired')
                    ) {
                        this.setPasswdExpired(true);
                    } else {
                        this.setPasswdExpired(false);
                    }

					if(AuthService.subject.observers.length > 0) {					
						AuthService.subject.next({});
					}
				}

				subject.next(res);
				subject.complete();
			},
			(error: any) => {
				subject.error(error);
			}
		);
			
		return subject;
	}

	logout(): void {
        
        this.service.logout().subscribe(
            (res: any) => {
                this.resetLoggedIn();
                AuthService.subject.next(res);
            },
            (error: any) => {
                AuthService.subject.error(error);
            }
        );
	}

    getLoggedIn(): boolean {        
		const lastReq: string = localStorage.getItem('user.lastreq');
		const now: number = Date.now();

		if(
			(localStorage.getItem('user.token') == null) ||
			(!lastReq) ||
			(!lastReq.match("[0-9]*")) ||
			((now - Number(lastReq)) > Config.SESSION_TIMEOUT)
		) {
			if(localStorage.getItem('user.token') != null) {
				this.logout();
			}
			
			return false;
		} else {
            this.resetLastRequest(now);			
			return true;
		}
	}
    
    getPasswdExpired(): boolean {
        return (localStorage.getItem('user.expired') != null);
    }
	
    setPasswdExpired(expired: boolean): void {
        
        if(expired) {
            localStorage.setItem('user.expired', '1');
        } else {
            localStorage.removeItem('user.expired');
        }
    }

    getSessionExpired(): boolean {
        return AuthService.sessionExpired;
    }
    
    setSessionExpired(): void {
        AuthService.sessionExpired = true;
        this.resetLoggedIn();
    }
        
	getAuthUser(): AuthModel {
		
		if(this.getLoggedIn()) {
			const data: string = localStorage.getItem('user.model');
			return JSON.parse(data) as AuthModel;
		}
	}
    
    resetLastRequest(now?: number): void {
        
        if(!now) {
            now = Date.now();
        }
        
        localStorage.setItem('user.lastreq', "" + now);
    }
    
    private resetLoggedIn(): void {
        localStorage.removeItem('user.token');
        localStorage.removeItem('user.model');
        localStorage.removeItem('user.expired');
        localStorage.removeItem('user.lastreq');
    }   
}