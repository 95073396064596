/* 
 *  Created on Jul 26, 2019
 *  Copyright Alfredo Marchini
 *  http://www.alfredomarchini.it
 */
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core'
import {Observable, Subject} from 'rxjs';

import {AmMessagesEvent} from './ammessages.event';

export const AmMessagesKey: InjectionToken<string> = new InjectionToken<string>("AmMessagesKey");

@Injectable()
export class AmMessagesModel {
    private static readonly dataSource: Subject<AmMessagesEvent> = new Subject();
    
    constructor(@Optional() @Inject(AmMessagesKey) public key: string) {
    }
    
    addInfo(text: string, key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_ADD,
            type: AmMessagesEvent.MESSAGE_TYPE_INFO,
            res: text,
            key: key || this.key
        });
    }
    
    addWarning(text: string, key?: string): void {
                
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_ADD,
            type: AmMessagesEvent.MESSAGE_TYPE_WARNING,
            res: text,
            key: key || this.key
        });
    }
    
    addError(text: string, key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_ADD,
            type: AmMessagesEvent.MESSAGE_TYPE_ERROR,
            res: text,
            key: key || this.key
        });
    }
    
    clearInfo(key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_CLEAR,
            type: AmMessagesEvent.MESSAGE_TYPE_INFO,
            key: key || this.key
        });
    }
    
    clearWarning(key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_CLEAR,
            type: AmMessagesEvent.MESSAGE_TYPE_WARNING,
            key: key || this.key
        });
    }
    
    clearError(key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_CLEAR,
            type: AmMessagesEvent.MESSAGE_TYPE_ERROR,
            key: key || this.key
        });
    }
    
    clearAll(key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_CLEAR,
            key: key || this.key
        });
    }
    
    processJsonResult(res: any, key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_JSON,
            res: res,
            key: key || this.key
        });
    }
    
    processHttpError(error: any, key?: string): void {
        
        AmMessagesModel.dataSource.next({
            cmd: AmMessagesEvent.MESSAGE_CMD_HTTP,
            res: error,
            key: key || this.key
        });
    }
    
    getDataSource(): Observable<AmMessagesEvent> {
        return AmMessagesModel.dataSource;
    }    
}