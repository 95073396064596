/* 
 *  Created on Jul 26, 2019
 *  Copyright Alfredo Marchini
 *  http://www.alfredomarchini.it
 */

export class AmMessagesEvent {
    static readonly MESSAGE_CMD_ADD: number = 1;
    static readonly MESSAGE_CMD_CLEAR: number = -1;
    static readonly MESSAGE_CMD_HTTP: number = 2;
    static readonly MESSAGE_CMD_JSON: number = 3;
    
    static readonly MESSAGE_TYPE_INFO: number = 1;
    static readonly MESSAGE_TYPE_WARNING: number = 2;
    static readonly MESSAGE_TYPE_ERROR: number = 3;
    
    cmd: number;
    type?: number;
    res?: any;
    key?: string;
}
