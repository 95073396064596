/* 
 * Created on Mar 6, 2017 10:40:46 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../auth/auth.service';
import {AmZoomModel} from '../theme/amzoom/amzoom.model';
import {AmZoomService} from '../theme/amzoom/amzoom.service';

@Injectable()
export class AuthGuard implements CanActivateChild {	
	
	constructor(
		private router: Router,
		private authService: AuthService,
		private zoomService: AmZoomService
	) {       
	}
    
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.checkLogin(state.url);
    }

	checkLogin(url: string): boolean {       
		
        if(this.authService.getLoggedIn()) {
            return true;
		}
		
		const zoom: AmZoomModel = this.zoomService.getZoomCurrent();
		
		if((zoom) && (url == zoom.routedst)) {
			this.authService.redirectUrl = zoom.routesrc;
		} else {
			this.authService.redirectUrl = url;
		}        
		
		this.router.navigate(['login']);
		return false;
	}
}