/* 
 * Created on Mar 20, 2017 12:09:40 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';

import {AuthRequestOptions} from '../auth/auth-request.options';
import {ContentRequestOptions} from './content-request.options';
import {RestfulService} from './restful.service';

export abstract class ManutService extends RestfulService {
	static readonly SAVE_WRITED: number = 1;
    static readonly UPLOADED_WRITED: number = 2;
	static readonly DELETE_WRITED: number = -1;
	
	readonly onDataWrited: Subject<any>;
	
	constructor(protected http: HttpClient, suffixUrl: string) {
		super(http);
		this.suffixUrl = suffixUrl;
		this.onDataWrited = new Subject<any>();
	}
	
	save(data: any, force?: boolean): Observable<any> {
		const subject: ReplaySubject<any> = new ReplaySubject(1); 
		let body: any;
		
		if(force) {
			body = {model: data, force: true};
		} else {
			body = {model: data};
		}
		
		super.rsPost("", body, ContentRequestOptions.CONTENT_TYPE_JSON).subscribe(
			(res: HttpResponse<any>) => {
				this.fireDataWrited(ManutService.SAVE_WRITED, res.body);				
				subject.next(res.body);
				subject.complete();				
			},
			(error: any) => {
				subject.error(error);
			}			
		);
		
		return subject;
	}
	
    del(id: any, det?: boolean, force?: boolean, args?: any): Observable<any> {
		const subject: ReplaySubject<any> = new ReplaySubject(1);
		let path: string = this.getRowIdUrl(id);
        let params: HttpParams;
        
        if((det) || (force)) {
            params = new HttpParams();
            
            if(det) {
                params = params.set("det", "" + det);
            }

            if(force) {
                params = params.set("force", "" + force);
            }                        
        }
        
        params = this.getOptionalArguments('del', args, params);

        if((params) && (params.keys().length > 0)) {
            path += "?" + params.toString();
        }

		super.rsDel("/" + path).subscribe(
			(res: HttpResponse<any>) => {
				this.fireDataWrited(ManutService.DELETE_WRITED, id);
				subject.next(res.body);
				subject.complete();				
			},
			(error: any) => {
				subject.error(error);
			}			
		);
		
		return subject;
	}
    
    upload(path: string, data: FormData, force?: boolean): Observable<any> {        
		const subject: ReplaySubject<any> = new ReplaySubject(1); 
        
        if(force) {
            data.append("force", "true");
        }
        
        super.rsPost(path, data).subscribe(
            (res: HttpResponse<any>) => {
				this.fireDataWrited(ManutService.UPLOADED_WRITED, res.body);				
				subject.next(res.body);
				subject.complete();				
            },
            (error: any) => {
                subject.error(error);
            }
        );
        
        return subject;
	}		
	
	getTable(cols: string[], searches?: string[], sort?: any, page?: any, args?: any): Observable<any> {        
        let params: HttpParams = this.getTableParams(cols, searches, sort, page);
        params = this.getOptionalArguments('getTable', args, params);
		return this.get("/table", params);
	}
	
	getSel(args?: any): Observable<any> {
		const params: HttpParams = this.getOptionalArguments('getSel', args);
		return this.get("/sel", params);
	}
    
    getAc(field: string, search: string, args?: any): Observable<any> {
        let params: HttpParams = new HttpParams().set('field', field).set('search', search);
        params = this.getOptionalArguments('getAc', args, params);
        return this.get("/ac", params);
    }
	
	getDet(id: any): Observable<any> {            
        const path: string = "/" + this.getRowIdUrl(id);
        return this.get(path);
    }
	
	getDetField(path: string, args?: any): Observable<any> {            
        const params: HttpParams = this.getOptionalArguments('getDetField', args);
		return this.get(path, params);
    }		
	
	getPrev(id: any, args?: any): Observable<any> {
		const path: string = "/prev/" + this.getRowIdUrl(id);
		const params: HttpParams = this.getOptionalArguments('getPrev', args);
		return this.get(path, params);
	}

	getNext(id: any, args?: any): Observable<any> {
		const path: string = "/next/" + this.getRowIdUrl(id);
		const params: HttpParams = this.getOptionalArguments('getNext', args);
		return this.get(path, params);
	}
	
	getFirst(args?: any): Observable<any> {
		const params: HttpParams = this.getOptionalArguments('getFirst', args);
		return this.get("/first", params);
	}
	
	getLast(args?: any): Observable<any> {
		const params: HttpParams = this.getOptionalArguments('getLast', args);
		return this.get("/last", params);
	}
	
	getBlob(path: string, args?: any): Observable<any> {
		const subject: ReplaySubject<any> = new ReplaySubject(1); 
        const params: HttpParams = this.getOptionalArguments('getBlob', args);
        
		if(params) {
			let strParams: string = params.toString();
			
			if(strParams.length > 0) {
				path += "?" + strParams;
            }
		}
						
		this.rsGet(path, undefined, ContentRequestOptions.RESPONSE_TYPE_BLOB).subscribe(
			(res: HttpResponse<any>) => {		
                		
				if(res.ok) {
					
					if(res.headers.get('content-type') == "application/json") {
						const blob: Blob = new Blob([res.body], {type: "application/json"});
						const fileReader: FileReader = new FileReader();
						
						fileReader.addEventListener('loadend', () => {
							const json: any = JSON.parse(fileReader.result + "");
							subject.next(json);
							subject.complete();
						});
						
						fileReader.readAsText(blob);						
					} else {
                        const tmp: string[] = res.headers.get('content-disposition').split("=");                                            
						const url: string = URL.createObjectURL(res.body);
						subject.next({result: true, url: url, filename: tmp[1]});
						subject.complete();
					}
					
				} else {
					subject.next({result: false});
					subject.complete();
				}
			},
			(error: any) => {
				subject.error(error);
			}
		);
		
		return subject;
	}	   
    
	getRowId(data: any): any {
		return data.id;
	}
	
	getRowRouterId(data?: any): any[] {		
        
		if((data) && (data.id)) {
			return [data.id];
		} else {
			return [0];
		}
	}

	getRowIdUrl(id?: any): string {                
		return (id) ? id : "0";
	}
		
	getRouterParamsId(params: any): any {   
		return +params['id'];
	}

	protected get(path: string, params?: HttpParams): Observable<any> {        
		const subject: ReplaySubject<any> = new ReplaySubject(1);
		
		if(params) {
			let strParams: string = params.toString();
			
			if(strParams.length > 0) {
				path += "?" + strParams;
			}
		}
				
		super.rsGet(path).subscribe(
			(res: HttpResponse<any>) => {				
				subject.next(res.body);
				subject.complete();
			},
			(error: any) => {
				subject.error(error);
			}			
		);
		
		return subject;
	}

	protected post(path: string, data?: any): Observable<any> {
		const subject: ReplaySubject<any> = new ReplaySubject(1);

		super.rsPost(path, data).subscribe(
			(res: HttpResponse<any>) => {
				subject.next(res.body);
				subject.complete();
			},
			(error: any) => {
				subject.error(error);
			}			
		);
		
		return subject;
	}

    protected getTableParams(cols: string[], searches?: string[], sort?: any, page?: any): HttpParams {
		let params: HttpParams = new HttpParams();
		
		for(var col of cols) {
			params = params.append("column", col);
		}
        
        if(searches) {
            for(var search of searches) {
                params = params.append("search", search);
            }
        }
        
		if(sort) {
			params = params.set("sort_active", sort.active);
			params = params.set("sort_direction", sort.direction);
		}
		
		if(page) {
			params = params.set("page_index", page.pageIndex);
			params = params.set("page_size", page.pageSize);
		}				
        
        return params;
    }

    protected getOptionalArguments(method: string, args?: any, params?: HttpParams): HttpParams {
        
        if(args) {
            return this.setURLParams(args, params);
        } else {
            return params;
        }
    }

	protected setURLParams(data: any, params?: HttpParams, prefix: string = ''): HttpParams {
		
		if(!params) {
			params = new HttpParams();
		}
		
        let value: any;
        
		Object.keys(data).forEach(key => {
			value = data[key];
			
			if((value !== null) && (value !== undefined)) {
				
				if(value instanceof Array) {
					const avalue: any[] = value as any[];
					
					for(var i = 0; i < avalue.length; i++) {
						params = params.append(prefix + key, avalue[i]);
					}
					
				} else {
					params = params.append(prefix + key, value);
				}
			}
		});
		
		return params;
	}
    
	protected getContentRequestOptions(content?: string, responseType?: string): ContentRequestOptions {
		return new AuthRequestOptions(content, responseType);
	}	
	
	protected fireDataWrited(method: number, data: any): void {
		
		if(this.onDataWrited.observers.length > 0) {
			this.onDataWrited.next({method: method, data: data});
		}
	}
}