/* 
 * Created on Mar 16, 2017 3:47:35 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Config} from './config';
import {ContentRequestOptions} from './content-request.options';

export abstract class RestfulService {
	protected suffixUrl: string;
	protected baseUrl: string;	
	
	constructor(protected http: HttpClient) {		
		this.baseUrl = Config.BASE_URL;
	}
	
	rsGet(path: string, content?: string, responseType?: string): Observable<any> {		
        const options: ContentRequestOptions = this.getContentRequestOptions(content, responseType);
		return this.rsGetOpt(path, options.getHttpHeaders(), options.getResponseType());
	}
	
	rsGetOpt(path: string, headers?: HttpHeaders, responseType?: any): Observable<any> {
		const url: string = this.getRequestUrl(path);
		const options: any = this.getHttpOptions(headers, responseType);
		return this.http.get(url, options);
	}
	
	rsPost(path: string, body: any, content?: string, responseType?: string): Observable<any> {
		const options: ContentRequestOptions = this.getContentRequestOptions(content, responseType);
		return this.rsPostOpt(path, body, options.getHttpHeaders(), options.getResponseType());
	}
	
	rsPostOpt(path: string, body: any, headers?: HttpHeaders, responseType?: any): Observable<any> {
		const url: string = this.getRequestUrl(path);
		const options: any = this.getHttpOptions(headers, responseType);
		return this.http.post(url, body, options);
	}
	
	rsDel(path: string, content?: string, responseType?: string): Observable<any> {
		const options: ContentRequestOptions = this.getContentRequestOptions(content, responseType);
		return this.rsDelOpt(path, options.getHttpHeaders(), options.getResponseType());
	}	
		
	rsDelOpt(path: string, headers?: HttpHeaders, responseType?: any): Observable<any> {
		const url: string = this.getRequestUrl(path);
		const options: any = this.getHttpOptions(headers, responseType);
		return this.http.delete(url, options);
	}	
		
	protected getContentRequestOptions(content?: string, responseType?: string): ContentRequestOptions {
		return new ContentRequestOptions(content, responseType);
	}
	
	protected getHttpOptions(headers?: HttpHeaders, responseType?: string): any {
		const options: any = {};
		
		options.observe = 'response';
		
		if(headers) {
			options.headers = headers;
		}
		
		if(responseType) {
			options.responseType = responseType;
		}
		
		return options;
	}
	
	protected getRequestUrl(path: string): string {
		let url: string = this.baseUrl;
		
		if(this.suffixUrl != null) {
			url += this.suffixUrl;
		}
		
		url += path;
		return url;
	}
}
