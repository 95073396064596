/* 
 * Created on Jul 20, 2017 9:57:39 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';

import {AmMenuItemComponent} from './ammenuitem.component';
import {AmSubMenuDirective} from './amsubmenu.directive';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		MatIconModule,
		MatListModule
	],
	declarations: [		
		AmMenuItemComponent,
		AmSubMenuDirective
	],
	exports: [
		AmMenuItemComponent
	]
})
export class AmMenuItemModule {	
}