/* 
 * Created on Jul 20, 2017 9:57:39 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {AmMessagesComponent} from './ammessages.component';
import {AmMessagesModel} from './ammessages.model';

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatButtonModule,
		MatCardModule,
		MatIconModule
	],
	declarations: [		
		AmMessagesComponent
	],
	exports: [
		AmMessagesComponent
	],
    providers: [
        AmMessagesModel
    ]
})
export class AmMessagesModule {	
}