/* 
 * Created on Jul 19, 2017 8:18:33 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {
    ChangeDetectorRef, ChangeDetectionStrategy, 
    Component, Input, OnDestroy, OnInit
} from '@angular/core';

import {Subscription} from 'rxjs';

import {AmMessageModel} from './ammessage.model';
import {AmMessagesEvent} from './ammessages.event';
import {AmMessagesModel} from './ammessages.model';

@Component({
	selector: 'am-messages',
	templateUrl: 'ammessages.html',
	styleUrls: ['ammessages.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmMessagesComponent implements OnDestroy, OnInit {
	infoMessages: AmMessageModel[];
	warningMessages: AmMessageModel[];
	errorMessages: AmMessageModel[];
	
    private sub: Subscription;
    
    @Input()
    key: string;
    
    constructor(
        private model: AmMessagesModel,
        private changeDetector: ChangeDetectorRef
    ) {
		this.infoMessages = [];
		this.warningMessages = [];
		this.errorMessages = [];
	}
    
    ngOnInit(): void {
            
        this.sub = this.model.getDataSource().subscribe((event: AmMessagesEvent) => {

            if(
                ((!this.key) && (!event.key)) || 
                (this.key === event.key)
            ) {

                switch(event.cmd) {
                    case AmMessagesEvent.MESSAGE_CMD_ADD:

                        switch(event.type) {
                            case AmMessagesEvent.MESSAGE_TYPE_INFO: 
                                this.addInfo(event.res);
                                break;
                            case AmMessagesEvent.MESSAGE_TYPE_WARNING:
                                this.addWarning(event.res);
                                break;
                            case AmMessagesEvent.MESSAGE_TYPE_ERROR:    
                                this.addError(event.res);
                        }

                        this.changeDetector.markForCheck();
                        break;
                    case AmMessagesEvent.MESSAGE_CMD_CLEAR:

                        switch(event.type) {
                            case AmMessagesEvent.MESSAGE_TYPE_INFO:
                                this.clearInfo();
                                break;
                            case AmMessagesEvent.MESSAGE_TYPE_WARNING:
                                this.clearWarning();
                                break;
                            case AmMessagesEvent.MESSAGE_TYPE_ERROR:    
                                this.clearError();
                                break;
                            default:
                                this.clearAll();
                        }

                        this.changeDetector.markForCheck();
                        break;
                    case AmMessagesEvent.MESSAGE_CMD_HTTP:
                        this.processHttpError(event.res);
                        this.changeDetector.markForCheck();
                        break;
                    case AmMessagesEvent.MESSAGE_CMD_JSON:
                        this.processJsonResult(event.res);
                        this.changeDetector.markForCheck();
                }
            }
        });            
    }
    
    ngOnDestroy(): void {
        
        if(this.sub) {
            this.sub.unsubscribe();
            delete this.sub;
        }
    }

	hasMessages(): boolean {
		
		return (
			((this.infoMessages != null) && (this.infoMessages.length > 0)) ||
			((this.warningMessages != null) && (this.warningMessages.length > 0)) ||
			((this.errorMessages != null) && (this.errorMessages.length > 0))
		);
	}		

	clearAll(): void {
		this.infoMessages = [];
		this.errorMessages = [];
		this.warningMessages = [];
	}	

	private processJsonResult(res: any): void {

		if(res.result) {
			
			if((res.infoMessages) && (res.infoMessages.length > 0)) {
			
				for(var message of res.infoMessages) {
				
					if(message) {
						this.addInfo(message);
					}
				}
			}
			
		} else {
			let added: boolean = false;
			
			if((res.fieldErrors) && (res.fieldErrors.length > 0)) {
				
				for(var field of res.fieldErrors) {
					
					if(field) {
						this.addError(field.name + ": " + field.message);
						added = true;
					}
				}
			}
			
			if((res.errorMessages) && (res.errorMessages.length > 0)) {
				
				for(var error of res.errorMessages) {
					
					if(error) {
						this.addError(error.message);
						added = true;
					}
				}
			}
				
			if(!added) {
				this.addError("Errore sconosciuto");
			}
		}
	}
	
	private processHttpError(error: any): void {
		this.addError("(Errore HTTP) " + error.statusText);		
	}
	
	private addError(message: string): void {
		
		for(var errorMessage of this.errorMessages) {
			
			if(errorMessage.text == message) {
				errorMessage.count++;
				return;
			}
		}
				
		this.errorMessages.push({text: message, count: 1});
	}
	
	private addWarning(message: string): void {
		
		for(var warningMessage of this.warningMessages) {
			
			if(warningMessage.text == message) {
				warningMessage.count++;
				return;
			}
		}
				
		this.warningMessages.push({text: message, count: 1});
	}
	
	private addInfo(message: string): void {
		
		for(var infoMessage of this.infoMessages) {
			
			if(infoMessage.text == message) {
				infoMessage.count++;
				return;
			}
		}
		
		this.infoMessages.push({text: message, count: 1});
	}
	
	private clearError(): void {
		this.errorMessages = [];
	}

	private clearWarning(): void {
		this.warningMessages = [];
	}
		
    private clearInfo(): void {
		this.infoMessages = [];
	}
};

