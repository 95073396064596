/* 
 *  Created on Jul 15, 2019
 *  Copyright Alfredo Marchini
 *  http://www.alfredomarchini.it
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BandieraComponent} from './bandiera.component';

@NgModule({
	imports: [
        CommonModule
    ],
    declarations: [
        BandieraComponent
    ],
    exports: [
        BandieraComponent
    ]
})
export class BandieraModule {
}