/* 
 * Created on Mar 16, 2017 11:15:22 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {HttpHeaders} from '@angular/common/http';

export class ContentRequestOptions {	
	static readonly CONTENT_TYPE_FORM: string = 'application/x-www-form-urlencoded';
	static readonly CONTENT_TYPE_JSON: string = 'application/json'
	static readonly CONTENT_TYPE_XML: string = 'application/xml'
	static readonly CONTENT_TYPE_MULTIPART_FORM: string = 'multipart/form-data';
	static readonly CONTENT_TYPE_TEXT: string = 'text/plain';
	
	static readonly RESPONSE_TYPE_JSON: string = 'json';
	static readonly RESPONSE_TYPE_TEXT: string = 'text';
	static readonly RESPONSE_TYPE_BLOB: string = 'blob';
	static readonly RESPONSE_TYPE_ARRAYBUFFER: string = 'arraybuffer';
		
	protected headers: HttpHeaders;
	protected responseType: string;
	
	constructor(contentType?: string, responseType?: string) {
		
		if(contentType) {		
			this.headers = new HttpHeaders().append("Content-Type", contentType);
		} else {
			this.headers = new HttpHeaders();
		}
		
		if(responseType) {
			this.responseType = responseType;
		}		
	}
	
	getHttpOptions(): any {
		const options: any = {headers: this.headers};

		if(this.responseType) {
			options.responseType = this.responseType;
		};		
	
		return options;
	}
	
	getHttpHeaders(): HttpHeaders {
		return this.headers;
	}	

	getResponseType(): string {
		return this.responseType;
	}
}