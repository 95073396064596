/* 
 * Created on Aug 19, 2017 11:51:35 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {
    AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, Component, 
    ElementRef, OnDestroy, OnInit, HostListener, ViewChild
} from '@angular/core';

import {Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {Subscription, Observable} from 'rxjs';

import {AuthModel, Ruoli} from '../auth/auth.model';
import {AuthService} from '../auth/auth.service';
import {Config} from '../base/config';
import {AmMenuItemModel} from '../theme/ammenuitem/ammenuitem.model';
import {I18nService} from '../theme/base/i18n.service';
import {MediaService} from '../theme/base/media.service';

import {PagesModel} from './pages.model';
import {PagesService} from './pages.service';

@Component({
    selector: 'pages',
    templateUrl: 'pages.html',    
    styleUrls: ['pages.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesComponent implements AfterViewInit, OnDestroy, OnInit {
	readonly version: string = Config.VERSION;
    readonly appName: string = Config.APPLICATION_NAME;

    navMode: string = 'over';
    authModel: AuthModel;
    pagesModel: PagesModel;
    menuItems: AmMenuItemModel[];
    productionMode: boolean = Config.PRODUCTION_MODE;
    pbarMode: string = 'determinate';
    pbarValue: number;
    pbarColor: string = 'primary';
	
    @ViewChild('sidenav', {static: true}) 
    sidenav: MatSidenav;	
	
    private subs: Subscription[];	
    private pbarOffset: number = 0;
    private resizeFn: () => void;
    
    constructor(
        public i18nService: I18nService,
        public mediaService: MediaService,
        private router: Router,
        private service: PagesService,
        private authService: AuthService,			
        private el: ElementRef,
        private changeDetector: ChangeDetectorRef
    ) { 		
        this.authModel = this.authService.getAuthUser();
    }
	
    ngOnInit(): void {
        this.subs = [];
        this.setMenuItems();
        this.pagesModel = this.service.getRoutePagesModel();		
//		this.setSideNav();

        this.subs[0] = this.mediaService.mediaChanged().subscribe(() => {
//			this.setSideNav();
            this.changeDetector.markForCheck();
        });

        this.subs[1] = this.service.onUrlChanged.subscribe((res: PagesModel) => {
                this.pagesModel = res;
//              this.setSideNav();
                this.expandMenu(this.menuItems, res.menu);
                this.changeDetector.markForCheck();
        });
        
        this.subs[2] = this.service.onLoaderChanged.subscribe((value: number) => {
            
            switch(value) {
                case PagesService.LOADER_ERROR:
                    this.pbarMode = 'determinate';
                    this.pbarValue = 40;
                    this.pbarColor = 'warn';
                    this.pbarOffset = 0;
                    this.changeDetector.markForCheck();
                    break;
                case PagesService.LOADER_LOADING:
                
                    if((++this.pbarOffset) == 1) {
                        this.pbarMode = 'indeterminate';
                        this.pbarColor = 'primary';
                        delete this.pbarValue;
                        this.changeDetector.markForCheck();
                    }
                
                    break;
                case PagesService.LOADER_IDLE:
                    
                    if((--this.pbarOffset) == 0) {
                        this.pbarMode = 'determinate';
                        this.pbarValue = 100;
                        this.pbarColor = 'primary';
                        this.changeDetector.markForCheck();
                    }
                    
                    break;
                default:
                    this.pbarMode = 'determinate';
                    delete this.pbarValue;
                    this.pbarColor = 'primary';
                    this.pbarOffset = 0;
                    this.changeDetector.markForCheck();                   
            }
        });
			
        this.subs[3] = this.service.onSidenavChanged.subscribe((navMode: string) => {
            this.pagesModel.navMode = navMode;
//			this.setSideNav();
            this.changeDetector.markForCheck();
        });
        
        this.subs[4] = this.authService.onLoginChanged().subscribe(() => {
            this.setMenuItems();
            this.changeDetector.markForCheck();
        });
			
        this.expandMenu(this.menuItems, this.pagesModel.menu);
        
        if(!Config.PRODUCTION_MODE) {
            
            this.resizeFn = () => {
                this.changeDetector.markForCheck();
            };
			
            window.addEventListener('resize', this.resizeFn);
        } else if(this.resizeFn) {
                window.removeEventListener('resize', this.resizeFn);
                delete this.resizeFn;
        }

        this.changeDetector.markForCheck();
    }
	
    ngAfterViewInit(): void {
        const item: HTMLElement = document.querySelector('mat-list-item.selected');
        const sidenav: HTMLElement = this.el.nativeElement.querySelector('mat-sidenav');	

        if(item) {			

            if(sidenav) {			
                sidenav.scrollTop = item.offsetTop;
                this.changeDetector.markForCheck();  
            }
        }
    }
	
    ngOnDestroy(): void {

        for(var sub of this.subs) {
            sub.unsubscribe();
        }

        delete this.subs;

        if(!Config.PRODUCTION_MODE) {
            window.removeEventListener('resize', this.resizeFn);
            delete this.resizeFn;
        }
    }
    
    logout(): void {
        this.authService.logout();
        this.router.navigate(['login']);
    }
    
    setLang(lang: string): void {
        this.i18nService.setLang(lang);
        window.location.reload();
    }

//    private setSideNav(): void {
//        let navMode: string;
//		
//        if(
//            (this.pagesModel) && 
//            (this.pagesModel.navMode)
//        ) {        
//            navMode = this.pagesModel.navMode;
//        } else if(this.mediaService.getActiveMedia() == 'xl') {
//            navMode = 'side';
//        } else {
//            navMode = 'over';
//        }
//        
//        if(this.navMode != navMode) {
//                    
//            if(navMode == 'side') {            
//                this.navMode = 'side';
//                this.sidenav.disableClose = true;
//
//                if(!this.sidenav.opened) {
//                    this.sidenav.open();                    
//                }
//
//            } else {
//
//                if(this.sidenav.opened) {
//                   this.sidenav.close();
//                }		
//
//                this.sidenav.disableClose = false;
//                this.navMode = navMode;
//            }
//        }
//    }
		
    @HostListener('click', ['$event'])
    pageClick(event: any): void {

        if(
            (event.target.classList[0] != 'sidenav-button') &&
            (event.target.innerText != 'menu') &&
            (this.sidenav.opened)
//            && (this.mediaService.getActiveMedia() != 'xl')
        ) {
            let parent: any = event.target;

            do {

                if(parent.classList.contains('menu-item')) {

                    if(parent.classList.contains('menu-tree')) {
                        return;
                    }

                    break;
                }

                parent = parent.parentElement;
            } while(parent != null);

            setTimeout(_=> {
                this.sidenav.close();
                this.changeDetector.markForCheck();
            }, 250);
        }
    }
	
    private expandMenu(menus: AmMenuItemModel[], menuIds: string[]): void {
        let menuId: string, submenuIds: string[];

        if((menuIds) && (menuIds.length > 0)) {
            menuId = menuIds[0];			
        } else {
            menuId = null;
        }	

        for(var menu of menus) {

            if((menu.children) && (menu.children.length > 0)) {												

                if(menu.id == menuId) {
                    menu.expanded = true;
                }

                if((menuIds) && (menuIds.length > 1)) {
                    submenuIds = menuIds.slice(1);
                } else {
                    submenuIds = undefined;
                }				

                this.expandMenu(menu.children, submenuIds);
            } else {				
                menu.selected = (menu.id == menuId);
            }
        }
    }
    
    private setMenuItems(): void {
        
        if(this.authModel.daatiput == Ruoli.RUOLO_FORNITO) {
            this.menuItems = PagesService.MENU_FORNITO_ITEMS;
        } else {
            this.menuItems = PagesService.MENU_ITEMS;
        }
    }
}