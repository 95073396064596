/* 
 * Created on Mar 16, 2017 5:46:06 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {environment} from '../../environments/environment';
import packageinfo from '../../../package.json';

export class Config {    
	static readonly VERSION: string = packageinfo.version;
	static readonly PRODUCTION_MODE: boolean = environment.production;
	static readonly BASE_URL: string = environment.baseUrl;
    static readonly APPLICATION_NAME: string = "Fiori - Ng2" + environment.titleSuffix;
    static readonly SESSION_TIMEOUT: number = 30*60*1000;
    static readonly DEFAULT_THEME: string = "deeppurple-amber";
}
