/* 
 * Created on Dec 22, 2018 9:20:35 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
 
const MEDIA_TYPES: any[] = [
    {label: 'xs', max: 599},
    {label: 'sm', min: 600, max: 959},
    {label: 'md', min: 960, max: 1279},
    {label: 'lg', min: 1280, max: 1919},
    {label: 'xl', min: 1920}
];
 
@Injectable()
export class MediaService {
	private static readonly subject: Subject<string> = new Subject();    
	private static activeMedia: string;
	
    private resizeFn: () => void;
    
    constructor() {		
        
        this.resizeFn = () => {
			const previous: string = MediaService.activeMedia;
			this.setActiveMedia();            
            
			if(previous != MediaService.activeMedia) {
				MediaService.subject.next(MediaService.activeMedia);
            }
        };
        
        window.addEventListener('resize', this.resizeFn);        
        this.setActiveMedia();
	}
	
	mediaChanged(): Observable<string> {
		return MediaService.subject;
	}
	
	getActiveMedia(): string {
		return MediaService.activeMedia;
	}
    
    getScreenWidth(): number {
        return window.innerWidth;
    }
	
	private setActiveMedia(): void {
        const width: number = this.getScreenWidth();
        
		for(var media of MEDIA_TYPES) {

            if(
                ((!media.min) || (media.min <= width)) &&
                ((!media.max) || (media.max >= width))
            ) {
                MediaService.activeMedia = media.label;
                break;
            }
		}
	}	
}

