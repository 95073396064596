/* 
 * Created on Nov 3, 2018 3:13:46 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class WorkspaceService {
	
	constructor(private location: Location) {		
	}
}

