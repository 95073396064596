/* 
 * Created on Mar 16, 2017 6:40:11 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';

import {ContentRequestOptions} from '../../../base/content-request.options';
import {ManutService} from '../../../base/manut.service';

@Injectable() 
export class UtenteService extends ManutService {
	
	constructor(protected http: HttpClient) {
		super(http, '/utente');
	}
	
	login(userLogin: string, userPasswd: string): Observable<any> {
        const subject: ReplaySubject<any> = new ReplaySubject(1);
        const options: ContentRequestOptions = new ContentRequestOptions(ContentRequestOptions.CONTENT_TYPE_FORM);
        const params: HttpParams = new HttpParams().append("userLogin", userLogin).append("userPasswd", userPasswd);
		
		this.rsPostOpt("/login", params, options.getHttpHeaders()).subscribe(
			(res: HttpResponse<any>) => {
				const model: any = res.body;
				subject.next(model);				
				subject.complete();
			},
			(error: any) => {
				subject.error(error);
			}
		);
		
		return subject;
	}
	
	passwd(daaopwut: string, daanpwut: string): Observable<any> {		
		const subject: ReplaySubject<any> = new ReplaySubject(1);
        const params: HttpParams = new HttpParams()
            .append("daaopwut", daaopwut)
            .append("daanpwut", daanpwut);
		
		this.rsPost("/passwd", params.toString(), ContentRequestOptions.CONTENT_TYPE_FORM).subscribe(
			(res: HttpResponse<any>) => {
				const model: any = res.body;
				subject.next(model);				
				subject.complete();
			},
			(error: any) => {
				subject.error(error);
			}		
		);
		
		return subject;		
	}
    
	logout(): Observable<any> {		
		return this.post("/logout");		
	}
}
