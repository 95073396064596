/* 
 * Created on Aug 16, 2017 6:21:59 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';

import {AmMenuItemModel} from './ammenuitem.model';

@Component({
	selector: 'am-menuitem',
	templateUrl: 'ammenuitem.html',
	styleUrls: ['ammenuitem.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AmMenuItemComponent {
	@Input() model: AmMenuItemModel;
	
	itemMenuClicked(item: AmMenuItemModel): void {
		
		if((item.children) && (item.children.length > 0)) {		
			item.expanded = !item.expanded;
		} else if(item.href) {
			item.selected = true;
		}
	}
}
