/*
 * Created on Aug 20, 2021
 * Copyright Amix System S.r.l.
 * Alla rights reserved.
 */
export class AmThemeConstants {

    static readonly THEME_KEY: string = "AMTHEME-APP";

    static readonly THEME_LIST: AmThemeMap[] = [
        {
            key:        'deeppurple-amber',
            label:      'Deep purple & Amber',
            href:       'deeppurple-amber.css',
            colorA: '#fff',
            colorB: '#ffc107',
            colorC: '#673ab7'
        },
        {
            key:    'indigo-pink',
            label:  'Indigo & Pink',
            href:   'indigo-pink.css',
            colorA: '#fff',
            colorB: '#ff4081',
            colorC: '#3f51b5'
        },
        {
            key:    'pink-bluegrey',
            label:  'Pink & Blue grey',
            href:   'pink-bluegrey.css',
            colorA: '#303030',
            colorB: '#607d8b',
            colorC: '#e91e63'
        },
        {
            key:    'purple-green',
            label:  'Purple & Green',
            href:   'purple-green.css',
            colorA: '#303030',
            colorB: '#4caf50',
            colorC: '#9c27b0'
        }
    ];
}

export class AmThemeMap {
    key: string;
    label: string;
    href: string;
    colorA: string;
    colorB: string;
    colorC: string;
}