/* 
 * Created on Apr 12, 2018 12:08:38 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
export class SelectionMode {
	static readonly NO_SELECTION: number = 0;
	static readonly SINGLE_SELECTION: number = 1;
	static readonly MULTI_SELECTION: number = 2;
}
