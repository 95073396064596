/*
 * Created on Aug 20, 2021
 * Copyright Amix System S.r.l.
 * Alla rights reserved.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

import {AmThemeComponent} from './amtheme.component';
import {AmThemeService} from './amtheme.service';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
    ],
    declarations: [
        AmThemeComponent
    ],
    exports: [
        AmThemeComponent
    ],
    providers: [
        AmThemeService
    ]
})
export class AmThemeModule {
}