/* 
 * Created on Mar 16, 2017 11:15:22 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {ContentRequestOptions} from '../base/content-request.options';

export class AuthRequestOptions extends ContentRequestOptions {
	
	constructor(content?: string, responseType?: string) {
		super(content, responseType);
		const userToken: string = localStorage.getItem('user.token');
		
		if(userToken != null) {
			this.headers = this.headers.append('Authorization', 'Bearer ' + userToken);
		}
	}
}
